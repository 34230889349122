<template>
    <div>
        <Header></Header>
        <div class="content">
            <div class="login-form">
                <div class="login-top spczjz">
                    <img :src="config.logoPhoto" alt="" height="40px">
                    {{config.miniApps || ''}}
                </div>
                <div class="login-title">找回密码</div>
                <div class="login-form-item">
                    <input type="text" placeholder="手机号" v-model="mobile" autocomplete="off">
                    <div class="iconfont icon-chacha" v-if="mobilelength" @click.stop="mobile=''"></div>
                </div>
                <div class="login-form-item">
                    <input type="text" placeholder="验证码" v-model.trim="code" autocomplete="new-password">
                    <div class="login-code-but">
                        <verification :mobile="mobile" :mark="'password_reset'"></verification>
                    </div>
                </div>
                <div class="login-form-item">
                    <input :type="password_see?'password':'text'" placeholder="新密码" v-model.trim="password" autocomplete="new-password">
                    <div class="iconfont icon-zhengyanicon" v-if="password_see" @click.stop="password_see=false"></div>
                    <div class="iconfont icon-icon-eye-close" v-else @click.stop="password_see=true"></div>
                </div>
                <div class="login-form-item-tips">

                </div>
                <div class="login-form-item login-form-but" @click="forget_password">
                    确认
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { sourc, sourc2,reset} from "@/apiUrl/index.js";
import verification from '@/components/verification.vue'
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["userInfo", "config","login_show","token"]),
    },
    components:{
        verification
    },
    data() {
        return {
            sourc,
            sourc2,
            mobile:'',
            code:'',
            mobilelength:false,
            password:'',
            password_see:true,
        };
    },
    watch:{
        mobile(a,b){
            a.length==0?this.mobilelength = false:this.mobilelength = true;
        }
    },
    created() {

    },
    methods: {
        forget_password(){
            if(this.mobile.length==0 || this.password.length==0){
                this.$msg('请输入手机号或新密码!',3)
                return
            }
            if(this.code.length==0){
                this.$msg('请输入验证码!',3)
                return
            }
            reset({mobilePhone:this.mobile,password:this.password,code:this.code}).then((res)=>{
                if(res.code==200){
                    this.$msg('修改密码成功!')
                    this.$link('/')
                    setTimeout(()=>{
                        this.$router.go(0)
                    },1000)
                }else{
                    this.$msg(res.message,3)
                }
            })
        },
    },
};
</script>

<style lang="less" scoped>
.content{
    padding-top: 80px;
}
.login-title{
    margin-bottom: 10px;
}
.login-top{
    height: 52px;
    font-size: 20px;
    font-weight: 700;
    font-family: cursive;
    background-color: #f7f7fc;
    position: relative;
    margin-bottom: 50px;
    img{
        margin-right: 6px;
    }
    .icon-cha2{
        position: absolute;
        right: 20px;
        top: 14px;
        color: #aaa;
        cursor: pointer;
    }
}
.login-form{
    padding: 0px 24px 0;
    box-sizing: border-box;
    width: 410px;
    margin: 0 auto;
    .login-form-item{
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #fff;
        border-radius: 4px 4px;
        padding: 0 16px;
        color: #222226;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        .iconfont{
            position: absolute;
            width: 20px;
            height: 20px;
            line-height: 20px;
            top: 12px;
            right: 16px;
            cursor: pointer;
            color: #aaa;
        }
        input{
            display: block;
            width: 100%;
            height: 100%;
            border: none;
            background: #fff;
            outline: none;
        }
        .login-code-but{
            position: absolute;
            right: 16px;
            top: 0px;
        }
    }
    .login-form-item+.login-form-item{
        margin-top: 16px;
    }
}
.login-form-item-tips{
    margin: 7px 0 24px;
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #222226;
    line-height: 16px;
    padding-left: 16px;
    padding-right: 16px;
    span{
        float: right;
        cursor: pointer;
    }
    span:hover{
        color: var(--color);
        text-decoration: underline;
    }
}
.login-form-but{
    background-color: var(--color) !important;
    color: #fff !important;
    line-height: 40px;
    border-radius: 999px !important;
    text-align: center;
    font-size: 16px !important;
    cursor: pointer;
}
</style>
